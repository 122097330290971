import {
   type JwtPayloadAdminObject,
   type AdminAppRoles,
} from '@innerwell/dtos';
import { getCookieSession } from '@innerwell/utils';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

type SessionUser = {
   username: string;
   exp: number;
   roles: AdminAppRoles[];
};

export const getSession = async (): Promise<SessionUser | null> => {
   const sessionInfo = getCookieSession<JwtPayloadAdminObject>('admin_');
   if (!sessionInfo) {
      return null;
   }

   return {
      username: sessionInfo.sub,
      exp: sessionInfo.exp,
      roles: sessionInfo['cognito:groups'],
   };
};

const redirectBaseUrl = '/';

export type SessionContextValue = (
   | { data: SessionUser; status: 'authenticated' }
   | {
        data: null;
        status: 'unauthenticated' | 'loading';
     }
) & {
   clearSession: () => void;
   refetchSession: () => Promise<SessionUser | null>;
};

const SessionContext = createContext<SessionContextValue>({
   clearSession: () => {},
   refetchSession: async () => {
      return null;
   },
   data: null,
   status: 'loading',
});

export function SessionProvider(props: {
   children: React.ReactNode;
   session?: SessionUser | null;
}) {
   const { children } = props;

   const [session, setSession] = useState<SessionUser | null>(null);

   /** If session was passed, initialize as not loading */
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      setLoading(true);

      const getNewSession = async () => {
         const newSession = await getSession();
         setLoading(false);

         if (newSession) {
            setSession(newSession);
         }
      };

      if (!session) {
         getNewSession();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const value = useMemo(
      () =>
         ({
            data: session,
            status: loading
               ? 'loading'
               : session
                 ? 'authenticated'
                 : 'unauthenticated',
            refetchSession: async () => {
               const refetchedSession = await getSession();
               setSession(refetchedSession);

               return refetchedSession;
            },
            clearSession: () => {
               setSession(null);
            },
         }) as SessionContextValue,
      [session, loading],
   );

   return (
      <SessionContext.Provider value={value}>
         {children}
      </SessionContext.Provider>
   );
}

type UseSessionOptions = {
   required?: boolean;
   onUnauthenticated?: () => void;
};

export const useSession = (options?: UseSessionOptions) => {
   const value: SessionContextValue =
      useContext<SessionContextValue>(SessionContext);

   if (!value && process.env.NODE_ENV !== 'production') {
      throw new Error('`useSession` must be wrapped in a <SessionProvider />');
   }

   const { required, onUnauthenticated } = options ?? {};

   const requiredAndNotLoading = required && value.status === 'unauthenticated';

   useEffect(() => {
      if (requiredAndNotLoading) {
         const url = `${redirectBaseUrl ?? ''}?${new URLSearchParams({
            error: 'SessionRequired',
            callbackUrl: window.location.href,
         }).toString()}`;
         if (onUnauthenticated) {
            onUnauthenticated();
         } else {
            window.location.href = url;
         }
      }
   }, [onUnauthenticated, requiredAndNotLoading]);

   if (requiredAndNotLoading) {
      return {
         data: null,
         status: 'loading',
         clearSession: value.clearSession,
         refetchSession: value.refetchSession,
      } as const;
   }

   return value;
};
