import { AdminAppRoles, type CognitoLoginDto } from '@innerwell/dtos';
import { useMutation } from '@tanstack/react-query';
import { type Route } from 'next';
import { useRouter, useSearchParams } from 'next/navigation';

import useThemedToast from '@/hooks/useThemedToast';

import { adminApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { getErrorMessage } from '@innerwell/utils';

export const useLogin = () => {
   const { toastSuccess, toastError } = useThemedToast();
   const { push } = useRouter();
   const searchParams = useSearchParams();
   const callbackUrl = searchParams.get('callbackUrl') as Route | null;

   const { refetchSession } = useSession();

   return useMutation({
      mutationFn: (data: CognitoLoginDto) => {
         return adminApiClient.authenticate.login({
            body: data,
            extraHeaders: {
               dontcheckcredentials: 'true',
            },
         });
      },

      onError: (error) => {
         const errorMessage = getErrorMessage(error);

         toastError(errorMessage);
      },

      onSuccess: async (res) => {
         if (res.status === 200) {
            const session = await refetchSession();

            if (!session) {
               toastError('Failed to fetch session');
               return;
            }

            toastSuccess('Successfully signed in');

            if (
               session?.roles.includes(AdminAppRoles.Admin) ||
               session?.roles.includes(AdminAppRoles.SuperAdmin)
            ) {
               push(callbackUrl ?? '/clinicians');
            } else {
               push('/patients');
            }
         } else if (res.status === 400) {
            toastError(res.body.message);
         }
      },
   });
};
